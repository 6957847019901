<template>
  <div
    :class="{ 'is-menu-open': isOpen }"
    class="block-layout-default"
  >
    <div class="block-layout-default__inner">
      <button
        type="button"
        class="block-layout-default__burger"
        @click="toggleMenu"
      >
        <div class="block-layout-default__burger-inner">
          <div class="block-layout-default__burger-line" />
          <div class="block-layout-default__burger-line" />
          <div class="block-layout-default__burger-line" />
        </div>
      </button>

      <div class="block-layout-default__logos">
        <div
          v-for="(logo, index) of logos"
          :key="`${logo.filename}-${index}`"
          class="block-layout-default__logos-wrapper"
        >
          <UtilRouteLink
            v-if="logo.link"
            :link="logo.link"
            :target="logo.target"
          >
            <AtomImage
              class="block-layout-default__logo is-positive"
              :image="{
                src: logo.url,
                alt: logo.alt,
              }"
            />
          </UtilRouteLink>

          <UtilRouteLink
            v-if="logo.link"
            :link="logo.link"
            :target="logo.target"
          >
            <AtomImage
              v-if="logo.negativeUrl"
              class="block-layout-default__logo is-negative"
              :image="{
                src: logo.negativeUrl,
                alt: logo.negativeAlt,
              }"
            />
          </UtilRouteLink>
        </div>
      </div>

      <div class="block-layout-default__links">
        <UtilRouteLink
          v-if="promoLink"
          :link="promoLink"
          target="_self"
          class="block-layout-default__promo-link"
        >
          <AtomButton
            :text="promoLinkText"
            :text-color="isLowerThanTablet
              ? backgroundColorMobile
              : 'var(--c-white)'"
            :background-color="isLowerThanTablet
              ? textColorMobile
              : textColorDesktop"
          />
        </UtilRouteLink>

        <nav class="block-layout-default__nav">
          <ul class="block-layout-default__list">
            <li
              v-for="(link, index) in links"
              :key="`${link.link}-${index}`"
              ref="listRefs"
              class="block-navigation-user__list-item"
            >
              <UtilRouteLink
                v-if="link.link"
                :link="link.link"
                class="block-navigation-user__list-link"
              >
                {{ link.text }}
              </UtilRouteLink>
            </li>
          </ul>
        </nav>

        <BlockNavigationLanguageDropdown
          class="block-layout-default__language"
          :text-color="isLowerThanTablet
            ? backgroundColorMobile
            : 'var(--c-white)'"
          :background-color="isLowerThanTablet
            ? textColorMobile
            : textColorDesktop"
        />

        <div class="block-layout-default__user--mobile">
          <BlockNavigationUser
            :links="userLinks"
            :background-color="backgroundColorMobile"
          />
          <AtomAvatar
            :initials="userInitials"
            :background-color="textColorMobile"
            :text-color="backgroundColorMobile"
          />
        </div>
      </div>

      <div class="block-layout-default__user--desktop">
        <transition name="fade-in">
          <BlockNavigationUser
            v-if="showUserNavigation"
            ref="userNavigationRef"
            :links="userLinks"
            :background-color="backgroundColorMobile"
            class="block-layout-default__user-navigation"
          />
        </transition>
        <AtomAvatar
          ref="avatarRef"
          :initials="userInitials"
          :background-color="textColorDesktop"
          text-color="var(--c-white)"
          @click="toggleUserNavigation"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
        Styles
    */
    backgroundColorDesktop: {
        type: String,
        default: 'transparent',
    },

    backgroundColorMobile: {
        type: String,
        default: 'var(--c-primary)',
    },

    textColorDesktop: {
        type: String,
        default: 'var(--c-primary)',
    },

    textColorMobile: {
        type: String,
        default: 'var(--c-white)',
    },

    /*
        Content
    */
    logos: {
        type: Array,
        default: () => [],
    },

    promoLinkText: {
        type: String,
        default: '',
    },

    promoLink: {
        type: String,
        default: '',
    },

    links: {
        type: Array,
        default: () => [],
    },

    userLinks: {
        type: Array,
        default: () => [],
    },
});

/*
    Styles
*/
const {
    backgroundColorDesktop,
    backgroundColorMobile,
    textColorDesktop,
    textColorMobile,
} = toRefs(props);

/*
    State
*/
const isOpen = ref(false);
const toggleMenu = () => {
    isOpen.value = !isOpen.value;
};

/*
    Close menu on route change on mobile
*/
const route = useRoute();
watch(() => route.path, () => {
    isOpen.value = false;
});

/*
    Mobile animation
*/
const listRefs = ref(null);

const { gsap } = await useGsap();

watch(isOpen, async () => {
    if (isOpen.value) {
        gsap.to(listRefs.value, {
            x: 0,
            autoAlpha: 1,
            duration: 0.6,
            ease: 'back.out(2.7)',
            stagger: 0.05,
            delay: 0.1,
        });
    } else {
        gsap.to(listRefs.value, {
            x: -30,
            autoAlpha: 0,
            duration: 1,
            ease: 'back.in(2.7)',
            delay: 0,
        });
    }
});

/*
    User Navigation
*/
const userInitials = computed(() => {
    const firstOfName = user.value?.prename?.charAt(0);
    const firstOfLastName = user.value?.name?.charAt(0);

    // If first name and last name are not available, return first two characters of email
    if (!firstOfName && !firstOfLastName) return '';

    // Otherwise initals are first character of first name and first character of last name
    return `${firstOfName || ''}${firstOfLastName || ''}`;
});
const showUserNavigation = ref(false);
const toggleUserNavigation = () => {
    showUserNavigation.value = !showUserNavigation.value;
};

/*
    Click outside
*/
const userNavigationRef = ref(null);
const avatarRef = ref(null);

onMounted(() => {
    onClickOutside(userNavigationRef, () => {
        toggleUserNavigation();
    }, {
        ignore: [avatarRef],
    });
});

watch(() => isOpen.value, (newValue) => {
    if (newValue) {
        stopLenis('global');
    } else {
        startLenis('global');
    }
});
</script>

<style lang="scss" scoped>
.block-layout-default {
    --background-color: v-bind(backgroundColorMobile);
    --text-color: v-bind(textColorMobile);

    @include tablet {
        --background-color: v-bind(backgroundColorDesktop);
        --text-color: v-bind(textColorDesktop);
    }

    @include fluid('padding-top', 30px, 25px, 30px);
    @include fluid('padding-bottom', 30px, 25px, 30px);
    @include z-index('header');

    position: absolute;
    top: 0;
    width: 100%;
    color: var(--text-color);
    pointer-events: none;

    @include tablet {
        height: unset;
        background-color: var(--background-color);
        pointer-events: all;
    }
}

.block-layout-default__inner {
    @include wrapper-layout;
    @include fluid('column-gap', 10px, 10px, 30px, false);

    display: flex;
    align-items: center;
}

.block-layout-default__logos {
    @include z-index('headerLogos');
    @include fluid('column-gap', 10px, 10px, 30px, false);

    display: flex;
    pointer-events: all;
}

.block-layout-default__logo {
    transition: opacity 0.25s ease-in-out;
    &.is-negative {
        position: absolute;
        top: 0;
        opacity: 0;
    }

    .is-menu-open & {
        opacity: 0;

        &.is-negative{
            opacity: 1;
        }
    }
}

.block-layout-default__logos-wrapper {
    position: relative;

    .block-layout-default__logo {
        @include fluid('width', 60px, 60px, 100px, false);
    }

    &:first-child {
        .block-layout-default__logo {
            @include fluid('width', 65px, 65px, 110px, false);
        }
    }
}

.block-layout-default__links {
    @include fluid('column-gap', 10px, 10px, 30px, false);

    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100dvh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: calc(var(--header-height) + 30px);
    padding-right: var(--page-padding);
    padding-bottom: 156px;
    padding-left: var(--page-padding);
    background-color: var(--background-color);
    opacity: 0;
    pointer-events: none;
    row-gap: 20px;
    transition: opacity 0.25s ease-in-out;

    .is-menu-open & {
        opacity: 1;
        pointer-events: all;
    }

    @include tablet {
        position: relative;
        width: 100%;
        height: auto;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 0;
        padding-top: unset;
        opacity: 1;
        pointer-events: all;
        row-gap: 0;
    }
}

.block-layout-default__promo-link {
    order: 2;
    margin-top: 40px;
    margin-right: auto;

    @include tablet {
        margin-top: 0;
    }
}

.block-layout-default__nav {
    order: 1;
}

.block-layout-default__language {
    order: 3;
}

.block-layout-default__promo-link,
.block-layout-default__nav,
.block-layout-default__language {
    @include tablet {
        order: 1;
    }
}

.block-layout-default__list {
    display: flex;
    flex-direction: column;

    column-gap: 30px;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--h3);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--h3);
    row-gap: 30px;

    @include tablet {
        flex-direction: row;
        font-size: var(--f-size--button);
        font-weight: var(--f-weight--700);
        line-height: var(--l-height--button);
        row-gap: none;
    }
}

.block-layout-default__user--mobile {
    position: absolute;
    right: var(--page-padding);
    bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 23px;

    @include tablet {
        display: none;
    }
}

.block-layout-default__user--desktop {
    position: relative;
    display: none;

    @include tablet {
        display: block;
    }
}

.block-layout-default__user-navigation {
    position: absolute;
    right: 0;
    bottom: -20px;
    transform: translateY(100%);
}

.block-layout-default__burger {
    @include z-index('headerLogos');

    position: absolute;
    right: var(--page-padding);
    display: block;
    display: flex;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-radius: var(--b-radius--full);
    background-color: var(--background-color);
    pointer-events: all;
    transition: background-color 0.25s ease-in-out;

    .is-menu-open & {
        background-color: var(--text-color);
    }

    @include tablet {
        display: none;
    }
}

.block-layout-default__burger-inner {
    position: relative;
    width: 13px;
    height: 10px;

    .is-menu-open & {
        height: 10px;
    }
}

.block-layout-default__burger-line {
    $duration: 0.35s;
    $delay: 0.3s;

    position: absolute;
    top: 0%;
    width: 10px;
    height: 2px;
    border-radius: 2px;
    background: var(--c-white);
    transition: top $duration ease-in-out, transform $duration ease-in-out, width $duration var(--ease-out--back) $delay, background-color $duration ease-in-out;

    &:nth-child(2) {
        top: 50%;
        width: 13px;
        transform: translateY(-50%);
    }

    &:last-child {
        top: 100%;
        width: 7px;
        transform: translateY(-100%);
    }

    .is-menu-open & {
        background-color: var(--background-color);

        transition: top $duration ease-in-out $delay, transform $duration ease-in-out $delay, width $duration var(--ease-in--back), background-color $duration ease-in-out;
        &:nth-child(2) {
            width: 0;
        }

        &:first-child {
            top: 50%;
            width: 100%;
            transform: translateY(-50%) rotate(45deg);
        }

        &:last-child {
            top: 50%;
            width: 100%;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}

.block-navigation-user__list-item {
    opacity: 0;
    transform: translateX(-30px);

    @include tablet {
        opacity: 1;
        transform: none;
    }
}
</style>
