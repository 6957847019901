<template>
  <div class="block-navigation-user">
    <ul class="block-navigation-user__list">
      <li
        v-for="(link, index) in links"
        :key="`${link.link}-${index}`"
      >
        <UtilRouteLink
          v-if="link.url"
          :link="link.url"
          class="block-navigation-user__list-link"
        >
          <IonIcon
            v-if="link.icon"
            :icon-name="link.icon"
            :icon-color="textColor"
          />

          {{ link.text }}
        </UtilRouteLink>

        <div
          v-else
          type="button"
          class="block-navigation-user__list-link"
          @click="handleClick(link.action)"
          @keypress="handleClick(link.action)"
        >
          <IonIcon
            v-if="link.icon"
            :icon-name="link.icon"
            :icon-color="textColor"
          />

          {{ link.text }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
        Styles
    */
    backgroundColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    textColor: {
        type: String,
        default: 'var(--c-white)',
    },

    borderRadius: {
        type: String,
        default: 'var(--b-radius--medium)',
    },

    /*
        Content
    */
    links: {
        type: Array,
        default: () => [],
    },
});

const {
    backgroundColor,
    textColor,
    borderRadius,
} = toRefs(props);

/*
    Advanced functions
*/
const handleClick = (action) => {
    switch (action) {
    case 'logout':
        useLogout('/login');
        break;
    default:
        break;
    }
};
</script>

<style lang="scss" scoped>
.block-navigation-user {
    --background-color: v-bind(backgroundColor);
    @include fluid('--f-size--button', 15px, 13px, 15px);
    position: relative;
    display: inline-flex;
    padding: 20px 0;
    border-radius: v-bind(borderRadius);
    background-color: var(--background-color);
    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--button);
    font-weight: var(--f-weight--700);

    @include tablet{
        padding: 15px 20px;
    }

    ::v-deep(.ion-icon__svg) {
        width: 20px;
        @include fluid('width', 15px, 22px, 22px);
    }

    &:after {
        @include fluid('right', 13px, 13px, 17.5px);

        position: absolute;
        top: 1px;
        display: none;
        width: 18px;
        height: 18px;
        border-radius: 2px;
        background-color: var(--background-color);
        content: '';
        pointer-events: none;
        transform: rotate(45deg);
        transform-origin: top right;

        @include tablet {
            display: block;
        }
    }
}

.block-navigation-user__list {
    display: flex;
    flex-direction: column;

    align-items: flex-end;
    row-gap: 20px;

    @include tablet {
        align-items: flex-start;
    }
}

.block-navigation-user__list-link {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
</style>
