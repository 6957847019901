<template>
  <div class="atom-avatar">
    <ClientOnly>
      {{ initials }}
    </ClientOnly>
  </div>
</template>

<script setup>
const props = defineProps({
    initials: {
        type: String,
        default: '',
    },

    image: {
        type: String,
        default: null,
    },

    backgroundColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    textColor: {
        type: String,
        default: 'var(--c-white)',
    },

    borderRadius: {
        type: String,
        default: 'var(--b-radius--full)',
    },
});

const {
    backgroundColor,
    textColor,
    borderRadius,
    image,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.atom-avatar {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-radius: v-bind(borderRadius);
    background-color: v-bind(backgroundColor);
    background-image: v-bind(image);
    background-position: center;
    background-size: cover;
    color: v-bind(textColor);
    cursor: pointer;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--h4);
    font-weight: var(--f-weight--500);
    line-height: var(--l-height--h4);
}
</style>
